<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 主体内容 -->
    <div class="BoxHeight bbox pb20 plr15">
      <div class="">
        <div class="textc f18 fw700 mt10">{{ title }}</div>
        <div class="text999 f12 textc mt10">{{$t('my.publishTime')}}: 2021-01-07 15:56</div>
        <div class="content text666 pt20">
          <div class="lh22 f13"
               v-html="content"></div>
        </div>
      </div>
      <!-- 下载列表 -->
      <div class="downloadList mt40">
        <div class="downloadItem flex f14 text333 align-center mb10"
             v-for="file in fileList"
             :key="file.id">
          <div class="tit bbox flex1"
               @click="toPreview(file)">
            {{ file.fileName }}
          </div>
        </div>
      </div>
      <!-- 立即考试 -->
      <div class="textc mt30"
           v-if="true == isExam">
        <el-button type="primary"
                   class="wp80 f16 Btn"
                   @click="gotoExamination(examCode, examTitle, time)">{{ examMsg }}</el-button>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue"
import Footer from "../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      isExam: false,
      examMsg: "开始考试",
      time: 1,
      title: "",
      noticeCode: "",
      content: "",
      fileList: [],
      examCode: "",
    };
  },
  methods: {

    getNotcieDetail (noticeCode) {
      var sendData = {};
      sendData.noticeCode = noticeCode;
      var that = this;
      this.$http
        .post("notice/queryNoticeDetail", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.content = res.data.data.content;
            that.fileList = res.data.data.fileList;
          }
        });
    },
    // 跳转到考试
    gotoExamination (examCode, title, time) {
      var sendData = {};
      sendData.examCode = examCode;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberExam/startMemberExam", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            var recordCode = res.data.data;
            localStorage.removeItem("examing");
            that.$router.push({
              path: "/examination",
              query: {
                examCode: examCode,
                title: title,
                time: time,
                recordCode: recordCode,
              },
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: "参加考试失败：" + res.data.message,
              showClose: true,
            });
          }
        });
    },
    toPreview (file) {
      var url = file.fileUrl;
      var content = file.represent;
      this.$router.push({
        path: "/previewFile",
        query: {
          url: url,
          content: content,
          typeCode: file.objectCode,
          typeName: "",
          machineType: "",
        },
      });
      that.$router.push({ path: "/picPreview", query: data });
    },
  },
  created () {
    this.title = this.$route.query.title;
    this.noticeCode = this.$route.query.noticeCode;
    this.examCode = this.$route.query.examCode;
    this.getNotcieDetail(this.noticeCode);
    if (
      !(
        typeof undefined === this.examCode ||
        null == this.examCode ||
        "" == this.examCode
      )
    ) {
      var sendData = {};
      sendData.examCode = this.examCode;
      var that = this;
      this.$http.post("exam/queryOne", sendData).then(function (res) {
        if (200 == res.data.code) {
          that.isExam = true;
          that.time = res.data.data.examTime;
          that.examTitle = res.data.data.title;
          if ("1" == res.data.data.type) {
            that.examMsg = "开始培训";
          }
        }
      });
    }
  },
};
</script>

<style scoped>
.content >>> h2,
h1,
h3 {
  font-size: 14px;
  color: #333;
}
.downloadItem .tit {
  padding: 6px 10px;
  background: #f5f5f5;
  border-radius: 5px;
}
.upload {
  width: 52px;
  margin-left: 10px;
}
</style>